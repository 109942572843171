
.dropdown-menu.show {
  background-color: aquamarine;
  border-color: black;
  box-shadow: 5px black;
}

.bg-dark {
  --bs-bg-opacity: 1;
  --bs-dark-rgb: 0, 152, 121; /* Set custom RGB color */
  /* background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; */
  background-color: aquamarine !important;
}

#basic-nav-dropdown {
  color: black;
  font-size: large;
  font-weight: 500;
}