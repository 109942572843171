
.active-input {
  /* border: 1px solid pink; */
  /*  background-color: #615cf3; Light pink background color */
  background-color: #fd86f9;
  color: white;
  font-weight: bold;
}
  
/* Below code is to remove increment decrement value bar for numeric inputbox */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

  
